<template>
  <div
    class="book-container"
    v-if="isShow"
  >
    <div class="title">{{titleText}}</div>
    <div class="form-item">
      <div class="sub-title">{{$t('login.meetTheme')}}</div>
          <el-input
          v-model.trim="myTextValue"
          :placeholder="myTextValue"
          clearable
          maxlength="50"
        ></el-input>
    </div>
    <div class="form-item date">
      <div>
        <div class="sub-title beijingA">
          {{$t('login.dateTime')}}
          <span class="beijingTime">{{$t('login.bjTime')}}</span> )
        </div>
        <el-date-picker
          v-model="minDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :clearable='false'
          class="date-item"
          prefix-icon='false'
          size='mini'
          @change="onClickDatePicker"
          :picker-options="pickerOptions"
          placeholder="请选择日期">
        </el-date-picker>
        <el-select v-model="timeSelect" size="mini" >
          <el-option
            v-for="item in selectTime"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div>
        <div class="sub-title">{{$t('login.duration')}}</div>
        <el-select v-model="hourSelect" size="mini">
          <el-option
            v-for="item in hourTime"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="minuteSelect" size="mini">
          <el-option
          v-for="item in minuteTime"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      </div>
    </div>
    <div class="form-item posi_style">
      <div class="sub-title inline-type">{{$t('login.meetPsd')}}</div>
      <el-checkbox class='check_style' :class="$i18n.locale === 'en-US' ? 'en_check' : 'zh_check'"  v-model="password.checked">{{$t('login.setMeetPwd')}}</el-checkbox>
        <el-input
          class="meetingPassword"
          v-model.trim="meetingPassword.value" 
          v-show="password.checked"
          :placeholder="$t('login.meetNo')"
          :minlength='4'
          :maxlength='6'
          :style="$i18n.locale === 'en-US' ? 'width:120px' : 'width:164px'"
      ></el-input>
    </div>
    <div class="form-item posi_style">
      <div class="sub-title inline-type">{{$t('login.mainPwd')}}</div>
      <el-checkbox  :class="$i18n.locale === 'en-US' ? 'en_checks' : 'zh_checks'"   v-model="hostPassword.checked">{{$t('login.setMainPwd')}}</el-checkbox>
        <el-input
        :minlength='4'
        :maxlength='6'
        class="meetingPassword"
        v-model.trim="ownerPassword.value" 
        v-show="hostPassword.checked"
        :placeholder="$t('login.onlyNo')"
        style="width:160px"
    ></el-input>
    </div>
    <div class="form-item block-flex-row">
      <div class="sub-title">{{$t('login.agenda')}}</div>
      <div v-if="iframeButtonText"
        class="sub-value"
        @click="iframeOpen"
      >{{iframeButtonText}}</div>
      <el-dialog
        :title="diaTitle"
        :visible.sync="isOK"
        width="600px"
        center
        @closed="iframeBeforeClosed"
      >
        <span class="iframe-box">
          <iframe
            :src="webviewUrl"
            frameborder="0"
            name="showHere"
            scrolling="auto"
            width="100%"
            height="530px"
            class="iframe-item"
            @load="iframeLoad"
            v-if="webviewUrl"
          >
          </iframe>
          <span
            class="iframe-loading-box"
            v-if="iframeLoading"
          >
            <img
              src="~@/assets/images/agendas_loading_48.gif"
              alt=""
              srcset=""
              class="iframe-loading-img"
            >
          </span>
        </span>
      </el-dialog> 
    </div>
    <div class="form-item">
      <div class="sub-title">{{$t('login.setMeet')}}</div>
      <el-checkbox class='checkbox_style'  v-model="allowEarlyEntry.checked">{{$t('login.joinMeetok')}}</el-checkbox>
      <el-checkbox class='checkbox_style'  v-model="muteJoinMeeting.checked">{{$t('login.mute')}}</el-checkbox>
      <el-checkbox class='checkbox_style'  v-model="allowSelfUnmute.checked">{{$t('login.relieve')}}</el-checkbox>
      <el-checkbox class='checkbox_style'  v-model="playTips.checked">{{$t('login.showTips')}}</el-checkbox>
    </div>
    <div class="form-item">
      <el-button class="form-item-cancel" @click="handleCancel">{{$t('login.cancel')}}</el-button>
      <el-button class="form-item-save" :loading="saveLoading" @click="handleSave">{{ saveLoading ? $t('login.saving') : $t('login.save')}}</el-button>
    </div>
  </div>
</template>

<script> 
import { meetAgendasUrl } from "@/config/index";
import {THROTLE_TIME} from "@/constant/index";
import {getAcceptLanguage} from "@/utils/auth";
import throttle from 'lodash.throttle'
import { fetchConference, fetchAgendaInfo, fetchConferenceSnowflake } from '@/api/meet'
import { checkSmallPwd  } from '@/utils/check'

export default {
  name: "Book",
  props: {
    userInfo: { default: null }, // 用户ID
    meetingId: { default: "" }, // 会议ID
  },
  computed: {
    agendaName() {
       return this.$store.state.user.realName + this.$t('login.whomeet')
    }
  },
  watch: {
    agendaName(value) {
      //主题值未发生改变 触发 
      if (!this.meetingId && this.myTextValue === this.tempTextValue) {
        this.myTextValue = value
        this.tempTextValue = value
      }
    }
  },
  data() {
    return {
      pickerOptions:{
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数
        }
      },
      diaTitle:this.$t('login.agenda'),
      item: null,
      isShow: false,
      titleText: this.$t('login.bookMeet'),
      myTextValue: "",
      tempTextValue: "",
      meetingPassword: { value: "" }, // 会议密码
      ownerPassword: { value: "" }, // 主持人密码
      // 会议密码
      password: {
        checked: false, // 是否开启会议密码
        collection: [{ id: 1, name: this.$t('login.setMeetPwd') }],
      },
      // 主持人密码
      hostPassword: {
        checked: false,
        collection: [{ id: 1, name: this.$t('login.setMainPwd') }],
      },
      // radio
      agendaPermission: 1,
      allowEarlyEntry: {
        checked: false,
        collection: [{ id: 1, name: this.$t('login.joinMeetok')  }],
      },
      muteJoinMeeting: {
        checked: false,
        collection: [{ id: 1, name: this.$t('login.mute')  }],
      },
      playTips: {
        checked: false,
        collection: [{ id: 1, name: this.$t('login.showTips')  }],
      },
      allowSelfUnmute: {
        checked: false,
        collection: [{ id: 1, name:this.$t('login.relieve') }],
      },
      minDate: "",
      selectTime: [],
      timeSelect:'',
      hourSelect:'',
      minuteSelect:0,
      hourTime:[],
      minuteTime:[
          { id: 0, name:this.$t('login.zerominute')  },
          { id: 15, name: this.$t('login.shwminute')  },
          { id: 30, name: this.$t('login.ssminute')  },
          { id: 45, name: this.$t('login.swminute') },
      ],
      postForm: { content: "" },
      agendaId: "", // 议程ID 
      webviewUrl: "",
      isOK: false,
      iframeLoading: true,
      iframeButtonText:'',
      saveLoading: false
    };
  },
  async created() {
    let that = this;
    that.minDate = that.$getDate();
    that.handleHours();// 计算时长小时数

    if (!this.meetingId) {
      that.handleTime();// 计算时间
      that.titleText = that.$t('login.bookMeet');
      that.isShow = true;
      that.allowEarlyEntry.checked = true;
      that.muteJoinMeeting.checked = true;
      that.allowSelfUnmute.checked = true;
      that.iframeButtonText = that.$t('login.add');
      that.myTextValue = that.agendaName
      that.tempTextValue = that.agendaName

      return;
    }

    that.titleText = that.$t('login.editMeet');

    try {
      const meetData = await fetchConference({
        id: that.meetingId
      })

      that.isShow = true;
      that.item = meetData;
      if (that.item.title === undefined || that.item === "") {
        return;
      }
      that.agendaId = that.item.agendaId;
      that.myTextValue = that.item.title; // 会议名称
      that.postForm.content = that.item.agenda; // 会议议程
      that.agendaPermission = that.item.agendaPermission; // 会议议程权限 0：所有人可见 1：仅主持人可见
      that.minDate = that.$dateFormat(
        that.item.beginTime,
        "yyyy-MM-dd"
      );
      that.handleTime();// 计算时间
      // 时间下拉菜单
      let hmTime = that.$dateFormat(
        that.item.beginTime,
        "hh:mm"
      )
      const filterItems = this.selectTime.filter(item => { return item.id === hmTime })
      if (filterItems.length === 0) {
        this.selectTime.unshift({
          id: hmTime,
          name: hmTime,
        })
      }
      that.timeSelect = hmTime
      let diff =
        that.$getLongTime(that.item.endTime, "yyyy-MM-dd hh:mm:ss") -
        that.$getLongTime(that.item.beginTime, "yyyy-MM-dd hh:mm:ss");

      let zMinutes = diff / 1000 / 60;
      let minutes = (diff / 1000 / 60) % 60;
      let hour = (zMinutes - minutes) / 60;

      that.hourSelect = hour; // 小时下拉菜单
      that.minuteSelect = minutes; // 分钟下拉菜单

      // 会议密码
      if (that.item.passwordEnable) {
        that.meetingPassword.value = that.item.password;
        that.password.checked = true;
      }

      // 主持人密码
      if (that.item.ownerPassword != "") {
        that.ownerPassword.value = that.item.ownerPassword;
        that.hostPassword.checked = true;
      }

      // 是否允许提前加入会议
      if (that.item.allowEarlyEntry) {
        that.allowEarlyEntry.checked = true;
      }

      // 成员加入会议是否静音
      if (that.item.muteJoinMeeting) {
        that.muteJoinMeeting.checked = true;
      }
      // 成员加入会议播放提示声
      if (that.item.playTips) {
        that.playTips.checked = true;
      }

      // 是否成员加入会议后自己解除静音
      if (that.item.allowSelfUnmute) {
        that.allowSelfUnmute.checked = true;
      }
      
      //初始化议程访问入口
      that.initAgendaLink()
    } catch (error) {
      console.log(error) // TODO:
    }
  },

  methods: {
    iframeBeforeClosed(e) {
      this.isOK = false
      //this.webviewUrl = ""
      this.iframeLoading = true
      this.initAgendaLink()
    },
    async initAgendaLink() {
      if (this.agendaId) {

        try {
          const data = await fetchAgendaInfo({
            conferenceId: this.meetingId,
            agendaId: this.agendaId
          })

          this.iframeButtonText = (data && data.agenda) ? this.$t('login.hasMeet'): this.$t('login.add')
        } catch (error) {
          console.log(error) // TODO:
        }
      } else {
         this.iframeButtonText = this.$t('login.add')
      }
    },
    async iframeOpen() {
      if (this.webviewUrl) {
         this.isOK = true
         this.iframeLoading = false
         return
      }
      let meetingId = this.meetingId
      let status = this.roleCode || 20
      let userId = this.userInfo?.userId || ""
      let jurisdiction = this.agendaPermission
      let language = getAcceptLanguage() === 'zh-CN' ? 'zh' : 'en'
      if (this.agendaId) {
        this.webviewUrl = meetAgendasUrl + "/h5/agendas?meetingId=" + meetingId + "&agendaId=" + this.agendaId + "&status=" + status + "&userId=" + userId + "&langue=" + language + "&jurisdiction=" + jurisdiction;
        this.isOK = true;
      } else {
        try {
          const agendaId = await fetchConferenceSnowflake()

          this.agendaId = agendaId
          this.webviewUrl = meetAgendasUrl + "/h5/agendas?meetingId=" + meetingId + "&agendaId=" + this.agendaId + "&status=" + status + "&userId=" + userId + "&langue=" + language + "&jurisdiction=" + jurisdiction;
          this.isOK = true;
        } catch (error) {
          console.log(error) // TODO: 
        }
      }
    },
    iframeLoad() {
      this.iframeLoading = false
    },
    baseTime(){
      for (let i = 0; i < 24; i++) {
          if (i < 10) {
            i = "0" + i;
          }
          this.selectTime.push({
            id: `${i}:00`,
            name: `${i}:00`,
          });
          this.selectTime.push({
            id: `${i}:30`,
            name: `${i}:30`,
          });
        }
        return this.selectTime
    },
    onClickDatePicker(date) {
      let that = this;
      that.selectTime = [];
      let currentDate = that.$getDate();
      let compare = that.$compareDate(currentDate, date);
      if (compare > 0) {
        that.baseTime()
      } else {
        that.handleTime();
      }
      that.timeSelect = that.selectTime[0].name;
    },
    handleTime() {
      let myDate = new Date();
      let hour = myDate.getHours();
      let myMinute = myDate.getMinutes();
      let currentDate = this.$getDate();
      let compare = this.$compareDate(currentDate, this.minDate);
      if(compare > 0){
        this.baseTime();
      }else{
        if (myMinute >= 0 && myMinute < 30) {
        for (let i = hour; i < 24; i++) {
          let tempHour = i + ''
          if (i < 10) {
              tempHour = "0" + i;
          }
          if (i > hour) {
            this.selectTime.push({
              id: `${tempHour}:00`,
              name: `${tempHour}:00`,
            });
          }
          this.selectTime.push({
            id: `${tempHour}:30`,
            name: `${tempHour}:30`,
          });
        }
      } else {
        for (let i = hour + 1; i < 24; i++) {
          if (i < 10) {
            i = "0" + i;
          }
          this.selectTime.push({
            id: `${i}:00`,
            name: `${i}:00`,
          });
          this.selectTime.push({
            id: `${i}:30`,
            name: `${i}:30`,
          });
        }
      }
      }
     
      this.timeSelect=this.selectTime[0].name;
    },
    handleHours() {
      for (let i = 0; i < 24; i++) {
        this.hourTime.push({
          id: i,
          name: `${i} ` + this.$t('login.hour'),
        });
      }
      this.hourSelect = this.hourTime[1].id;
    },
    showConfirm(tips){
      this.$confirm(tips,{
        confirmButtonText: this.$t('login.sure'),
        cancelButtonText: this.$t('login.cancel'),
        type: 'error'
      });
    },
    // 保存预约会议
    handleSave:throttle(function(){
      let that = this;
      if(that.myTextValue === ''){
        this.showConfirm(that.$t('reg.emptyMeetName'))
        return
      }
      let pwChecked = that.password.checked;
      let hwChecked = that.hostPassword.checked;
      if((pwChecked && that.meetingPassword.value === '') || (hwChecked && that.ownerPassword.value === '')){
        this.showConfirm(that.$t('reg.emptyPwd'))
        return
      }
      
      if((pwChecked && !checkSmallPwd(that.meetingPassword.value)) || (hwChecked && !checkSmallPwd(that.ownerPassword.value))){
        this.showConfirm(that.$t('reg.huiyiPwd'))
        return
      }

      let hours = this.hourSelect;
      let minutes = this.minuteSelect;

      let data = {
        agenda: this.postForm.content, //会议议程
        agendaPermission: this.agendaPermission, // 会议议程权限 0：所有人可见 1：仅主持人可见

        beginTime: `${this.minDate} ${this.timeSelect}:00`, // 开始时间
        endTime: "", // 结束时间

        password: this.password.checked ? this.meetingPassword.value : "", // 入会密码
        passwordEnable: this.password.checked ? 1 : 0, // 是否开启入会密码 0 关闭，默认；1 开启
        ownerPassword: this.hostPassword.checked
          ? this.ownerPassword.value
          : "",
        title: this.myTextValue, // 会议名称
        type: 1, // 1:预约会议 2:即时会议
        useSelfConference: 0, // 是否使用个人会议号   1:是 0：否
        allowEarlyEntry: this.allowEarlyEntry.checked ? 1 : 0, // 是否允许提前进入 1：是；0否；
        muteJoinMeeting: this.muteJoinMeeting.checked ? 1 : 0, // 成员加入会议静音 1：是；0否；
        playTips: this.playTips.checked ? 1 : 0, // 成员加入会议时播放提示音1：是；0否；
        allowSelfUnmute: this.allowSelfUnmute.checked ? 1 : 0, // 允许成员自我解除静音;1是；0：否
        agendaId: this.agendaId, // 议程ID
      };
        data.endTime = that.$getDateByLong(
        that.$getLongTime(data.beginTime) +
        hours * 60 * 60 * 1000 +
        minutes * 60 * 1000,
        "yyyy-MM-dd hh:mm:ss"
      ); 
      //校验时间 开始时间不能早于当前时间
      let dateCompare = this.$compareDate(new Date(data.beginTime), new Date());
      if (dateCompare > 0) {
        this.showConfirm(that.$t('login.timeRureire'))
        return;
      } 
      this.saveLoading = true
      if (this.meetingId) {
        data.id = that.item.id;
        this.$emit("handleUpdate", data);//编辑会议
      } else {
        this.$emit("handleSave", data, () => this.saveLoading = false);//预约新会议
      }
    },THROTLE_TIME, { leading: true, trailing: false}),
    // 预约会议页面点击取消
    handleCancel() {
      this.$emit("handleCancel");
    },
  },
};
</script>

<style lang="scss">
.book-container {
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 16px !important;
  }
}
</style>

<style scoped lang="scss">
  @mixin iframe-box($posi,$height){
    display: flex;
    align-items: center;
    justify-content: center;
    position: $posi;
    height: $height;
  }
  @mixin bj_style($w,$h,$s,$c){
    width: $w;
    height: $h;
    font-size: $s;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $c;
  }
  @mixin form-item-style($w,$h,$s,$bg,$c,$border:none){
      @include bj_style($w,$h,$s,$c);
      border-radius: 4px;
      padding:0 10px;
      background:$bg;
      border: $border;
    }
@mixin title_style($s,$w,$c,$l:0){
  font-size: $s;
  font-weight: $w;
  color: $c;
  line-height: $l;
}
.book-container {
  .title {
    @include title_style(20px,500,#333,28px);
    text-align: center;
  }

  .block-flex-row {
    display: flex;
  }
  ::v-deep .input-text-parent .input-box{
    font-size: 14px;
  }
  .posi_style{
    display: flex;
    align-items : center; 
    ::v-deep  .input{
       margin-right:0;
    }
    ::v-deep .el-input__inner{
      height: 30px;
      line-height: 30px;
    }
  .item-margin {
      margin-left: 38px;
      margin-top:-8px;
    }
    .meetingPassword,.ownerPassword{
      margin-top:-6px;
  }
  }
      
  .form-item {
    margin-top: 16px;
    .inline-type {
      display: inline-block;
    }
    .sub-title {
      @include title_style(14px,500,#333,20px);
      margin-bottom: 10px;
      clear: both;
    }
    .zh_check{
      margin:-6px 22px 0 32px;
    }
    .en_check{
      margin:-6px 22px 0 16px;
    }
    .zh_checks{
      margin:-6px 22px 0 18px;
    }
    .en_checks{
      margin:-6px 22px 0 38px;
    }
    .checkbox_style{
      display: block;
    }
    ::v-deep .el-checkbox,
      ::v-deep .el-checkbox__input.is-checked  .el-checkbox__label {
        @include title_style(14px,300,#666,20px);
        font-family: PingFangSC-Regular, PingFang SC;
      }
      ::v-deep .el-checkbox__inner {
        font-family: 'iconfont' !important;
         content: "\e6f1" !important;
      } 
     ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
          font-family: 'iconfont' !important;
          content: "\e6f2" !important;
      } 
    .sub-value {
      margin-bottom: 10px;
      font-size: 14px;
      color: #1ab370;
      margin-left: 38px;
      cursor: pointer;
    }

    &:last-child {
      margin-top: 40px;
      text-align: right;
      padding-bottom: 40px;
    }

    ::v-deep.datePickerInputOuter {
      width: 120px;
      .input-text-parent:last-child,.datePickerInputCenter {
        display: none;
      }
    }
    .form-item-cancel {
      @include form-item-style(72px,30px,14px,#FFF,#333,1px solid rgba(0, 0, 0, 0.2));
    }
    .form-item-save{
      @include form-item-style(auto,30px,14px,#1AB370,#FFF);
      min-width: 72px;
      &:hover{
        background: #3BBF82;
      }
      &:focus{
        background: #0E8C5A;
      }
    }
  }

  .date {
    display: flex;
    .date-item {
      overflow: hidden;
      display: inline-block;
      float: left;
    }
    ::v-deep .el-input--prefix .el-input__inner{
      padding-left:13px;
    }
    ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
      width:120px
    }
    ::v-deep .el-input__inner:focus{
        color: #1AB370;
      }
      ::v-deep .el-input__inner{
        width: 106px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        margin-right:6px;
      }
  }
}

.iframe-box {
  @include iframe-box(relative,530px);
  .iframe-loading-box {
    width: 100%;
    background: #fff;
  @include iframe-box(absolute,100%);
    .iframe-loading-img {
      width: 48px;
      height: 48px;
      margin-top: -60px;
    }
  }
}

.form-item .primary {
  float: right;
}
.beijingTime {
  @include bj_style(61px,18px,13px,#666);
  line-height: 18px;
}

::v-deep .el-button.is-loading span {
  vertical-align: bottom;
}
</style>