import dayjs from 'dayjs'
import { getAcceptLanguage } from '@/utils/auth'
import { LANG_ZH } from '@/constant/index'

export const formatAMPM = (startTime, endTime) => {
const str = dayjs(startTime).format('A HH:mm').replace(/AM 12:00/g,'AM 00:00') + ' - ' + dayjs(endTime).format('A HH:mm')
  const langVal = getAcceptLanguage()
  const moring = langVal=== LANG_ZH ? '上午' : 'AM '
  const afternoon = langVal === LANG_ZH ? '下午' : 'PM '
  return str.replace(/AM/g, moring).replace(/PM/g, afternoon)
}
